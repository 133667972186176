/**
 * Created by xiongxiaomeng.
 * 验证计划明细相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('verifyplandetail/info', params)},
    list: params => {  return API.GET('verifyplandetail/list', params)},
    get: params => {  return API.GET('verifyplandetail/get', params)},
    delete: params => {  return API.DELETE('verifyplandetail/delete', params)},
    config: params => {  return API.GET('verifyplandetail/config', params)}
}
