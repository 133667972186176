<template>
  <div>
    <el-card style="margin-bottom: 5px; text-align: center;">
      <el-row>
        <el-col :span="10" style="text-align: right; line-height: 40px; padding-right: 5px;">
          {{customerInfo.name}}
        </el-col>
        <el-col :span="4">
          <el-input v-model="verifyPlanInfo.name"></el-input>
        </el-col>
        <el-col :span="10" style="text-align: left; line-height: 40px; padding-left: 5px;">
          年度验证计划
        </el-col>
      </el-row>
    </el-card>

    <el-table :data="pmpage.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column label="项目" width="400">
        <template  #default="scope">
          <el-row>
            编号：{{scope.row.project.reportno}}
          </el-row>
          <el-row>
            <el-col :span="6" style="text-align: left"><i class="el-icon-s-home" style="font-size: 50px;"></i></el-col>
            <el-col :span="18" style="text-align: left; margin-top: 10px;">名称：{{scope.row.project.name}}</el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="参数" width="700">
        <template  #default="scope">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-select v-model="scope.row.type" filterable placeholder="请选择类型" style="width: 100%">
                <el-option v-for="item in verifyPlanTypes" :key="item.value" :label="item.name"  :value="parseInt(item.value)"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
<!--              <el-input placeholder="负责人" v-model="scope.row.uid"></el-input>-->
              <el-select v-model="scope.row.uid" placeholder="负责人">
                <el-option
                    v-for="item in userList"
                    :key="item"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
<!--              <el-input placeholder="计划验证时间" v-model="scope.row.planveriftime"></el-input>-->
              <el-date-picker
                  v-model="scope.row.planveriftime"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="12">
              <el-input type="textarea" :rows="2" placeholder="主要实施部门及成员" v-model="scope.row.execdeptuser"></el-input>
            </el-col>
            <el-col :span="12">
              <el-input type="textarea" :rows="2" placeholder="备注" v-model="scope.row.memo"></el-input>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button v-if="auth['verifyplandetail:del']" type="text" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer" style="text-align: right; margin-top: 10px;">
<!--      <el-button @click="editDigShow=false">取 消</el-button>-->
      <el-button type="success" @click="handle_selectPM()">选择项目</el-button>
      <el-button v-if="auth['verifyplandetail:add']" type="primary" @click="handle_save()">确 定</el-button>
    </div>

    <PMSelectDig :editDigTitle="pmselectTitle" :customerInfo="customerInfo" :editDigShow="pmselectShow"></PMSelectDig>

  </div>

</template>

<script>
import VERIFY from '../../api/biz/api_verifyplan';
import VERIFY_DETAIL from '../../api/biz/api_verifyplandetail';
import USER from '../../api/base/api_user';
import CUS from "@/api/biz/api_customer";
import PMSelectDig from '../project/listSelectDialog';
import COMM from "@/api/base/api_comm";
import moment from "moment"
import WAREHOUSE from "@/api/biz/api_customerwarehouse";

export default {
  components: {
    PMSelectDig
  },
  props: {
    editDigShow:{
      type: Boolean
    },
    editDigTitle: {
      type: String
    },

    // verifyPlanInfo: {
    //   type: Object
    // }
  },
  data(){
    return {
      showLoading: false,
      pmpage: {list: [], pageNum: 1, pageSize: 10, total: 0},
      customerInfo: {
        id: this.$route.params.customerid
      },
      editRules: {
        name: [{required: true, message: '验证计划名称', trigger: 'blur'}, {
          min: 2,
          max: 25,
          message: '长度在 2 到 25 个字符',
          trigger: 'blur'
        }]
      },
      pmselectTitle: '选择项目',
      pmselectShow: false,

      verifyPlanInfo: {
        id: this.$route.params.planid,
        custid: this.$route.params.customerid
      },
      verifyPlanDetailList: [],

      // 字典数据
      verifyPlanTypes: [],
      userList: [],
      auto: {}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/customer');
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'verifytype'}).then((res) => {
        this.verifyPlanTypes = res;
      });

      USER.listNoPage({}).then((res) => {
        this.userList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      let planid = this.$route.params.planid;
      let customerid = this.$route.params.customerid;

      // 客户信息
      CUS.get({
        id: customerid
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          this.customerInfo = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });

      // 验证计划ID为空时，不查询
      if (planid == 'null' || planid == null || planid < 0) {
        this.pmpage.list = [];
        return;
      }

      // 验证计划
      VERIFY.get({
        id: planid
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          this.verifyPlanInfo = res.data;
        } else {
          this.$message.error(res.msg);
          this.initAddData();
        }
      });

      // 执行查询
      VERIFY_DETAIL.list({
        pageNum: this.pmpage.pageNum,
        pageSize: this.pmpage.pageSize,
        planid: planid
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          console.log(data);
          this.pmpage.total = data.total;
          this.pmpage.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.pmpage.list = [];
        }
      });
    },

    // 选择项目
    handle_selectPM() {
      this.pmselectShow = true;
    },

    // 删除项目
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (row.id >= 0) {
          VERIFY_DETAIL.delete({id: row.id}).then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
            } else {
              this.$message.error(res.msg);
            }
          });
        }

        this.pmpage.list.splice(index,1);
        this.pmpage.pageSize = this.pmpage.pageSize - 1;

      }).catch(() => {
      });
    },

    handle_save() {

      if (this.verifyPlanInfo.name == null) {
        this.$message.warning('验证计划名称不能为空！');
        return;
      }

      let pmpageList = this.pmpage.list;
      for (const keyval in pmpageList) {
        let pmpageobj = pmpageList[keyval];

        // 日期时间格式
        let planveriftime = pmpageobj['planveriftime'];
        pmpageobj['planveriftime'] = moment(planveriftime).format('YYYY-MM-DD HH:mm:ss');

        this.verifyPlanDetailList.push(pmpageobj);
      }

      VERIFY.infodetails({
        verifyPlan: this.verifyPlanInfo,
        verifyPlanDetailArray: this.verifyPlanDetailList
      }).then((res) => {
        if (200 == res.code) {
          this.$message.success("操作成功！");
          this.$parent.editVerifyShow=false;
          this.$parent.initPageData();
        } else {
          this.$message.error(res.msg);
        }
      }).catch(() => console.log('promise catch err'));

    },
  }
}
</script>

<style scoped>
  .red {
    color: #ff0000;
  }
</style>