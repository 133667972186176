<template>
    <el-dialog :title="editDigTitle" width="800px" v-model="editDigShow" :close-on-click-modal="false">
      <el-table ref="multipleTable" @selection-change="handle_changeSelect" :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
        <el-table-column type="index" label="序号" width="100"></el-table-column>
        <el-table-column prop="name" label="项目名称" min-width="300"></el-table-column>
        <el-table-column prop="devid" label="项目编号" width="200"></el-table-column>
        <el-table-column type="selection" width="100"></el-table-column>

        <!--<el-table-column prop="scope" label="操作" min-width="100">
          <template  #default="scope">
            <el-checkbox v-model="scope.row.id" label="勾选" border
                         @change="handle_checked(value, scope.row.id)" ></el-checkbox>
          </template>
        </el-table-column>-->
      </el-table>
      <!--<el-pagination @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>-->

      <div slot="footer" class="dialog-footer" style="text-align:right; margin-top: 10px;">
        <el-button @click="handle_cancelSelect()">取消选择</el-button>
        <el-button @click="handle_cancel()">关闭</el-button>
        <el-button type="primary" @click="handle_save('proInfo')">确 定</el-button>
      </div>
    </el-dialog>
</template>

<script>
import PM from '../../api/base/api_pm';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
export default {
  name: "pmselectdig", // 项目选择列表
  components: {
  },
  props: {
    editDigShow:{
      type: Boolean
    },
    editDigTitle: {
      type: String
    },
    customerInfo: {
      type: Object
    }
  },
  data() {
    return {
        showLoading: false,
        page: {list: [], pageNum: 1, pageSize: 10, total: 0},
        multipleSelection: []
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      let did = this.$store.state.cuttuser.deptId, nodeType = 0;

      PM.list({
        did: 0,
        nodeType: 0,//节点的类型
        // status: 3, // 待验证项目
        custid: this.customerInfo.id,
        /*pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,*/
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 项目勾选事件
    handle_cancelSelect(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handle_changeSelect(val) {
      let plandetaillist = [];
      for (const valKey in val) {
        let pmobj = val[valKey];
        let plandetail = {};
        plandetail['project'] = pmobj;
        plandetail['pmid'] = pmobj.id;

        plandetaillist.push(plandetail);
      }

      this.multipleSelection = plandetaillist;
    },

    // 确定
    handle_save() {
      let pmlistorg = this.$parent.pmpage.list;
      let count = 0;
      for (const valKey in this.multipleSelection) {
        let pmnew = this.multipleSelection[valKey];
        let existsflag = false;
        console.log(pmnew);
        for (const key in pmlistorg) {
          if (pmlistorg[key]['pmid'] == pmnew['pmid']) {
            existsflag = true;
            this.$message.warning('验证计划已存在项目：' + pmnew['project']['name']);
            return;
          }
        }

        if (existsflag == false) {
          this.$parent.pmpage.list.push(this.multipleSelection[valKey]);
          count++;
        }
      }

      this.$parent.pmpage.pageNum = 1;
      this.$parent.pmpage.pageSize = this.$parent.pmpage.pageSize + count;
      this.$parent.pmselectShow=false;
    },

    // 取消
    handle_cancel() {
      this.$parent.pmselectShow=false;
    },
  }
}
</script>